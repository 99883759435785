import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Control, Controller } from "react-hook-form";
import { IFormInput } from "../interfaces/interfaces";

interface NumericTextFieldProps {
  name: string;
  label: string;
  nodeId: number;
  control: Control<IFormInput, any>;
  className: string;
  handleAirValueChange?: (id: number, field: string, value: number) => void;
}

const ControlledNumericTextField: React.FC<NumericTextFieldProps> = (
  props: NumericTextFieldProps
) => {
  const cleanNumericInput = (value: string) => {
    return value.replace(/[^\-0-9.,]/g, "").replace(/(?!^)-/g, "").replace(/[,\.](?=.*[,.])/g, "").replace(",", ".")
  }
  const [isSamsung, setIsSamsung] = useState(false);
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || "";
    const samsungPattern = /samsung/i;
    setIsSamsung(samsungPattern.test(userAgent));
  }, []);
  return (
    <Controller
      name={props.name && props.name.length > 0 ? `${props.name}_${props.nodeId}` : `val_${props.nodeId}` }
      control={props.control}
      defaultValue=""
      rules={{validate: (value) => /^-?[0-9]*[.,]?[0-9]*$/.test(value) || 'Ogiltig input'}}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          label={props.label}
          type="text"
          inputProps={{
            inputMode: isSamsung ? "text" : "text",
            pattern: "-?[0-9]*[.,]?[0-9]*",
            step: "0.01",
          }}
          className={props.className}
          id={props.name && props.name.length > 0 ? `${props.name}_val_${props.nodeId}` : `val_${props.nodeId}`}
          onChange={(e: any) => {
            const numericValue = cleanNumericInput(e.target.value)
            if (props.name && props.name.length > 0 && props.handleAirValueChange !== undefined) {
              props.handleAirValueChange(
                props.nodeId,
                props.name,
                parseFloat(numericValue)
              );
            }
            field.onChange(numericValue)
          }}
          onClick={(e) => e.stopPropagation()}
          onFocus={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          data-type={props.name && props.name.length > 0 ? props.name : "val"}
          data-id={props.nodeId}
          error={!!fieldState.error}
          helperText={fieldState.error ? fieldState.error.message : null}
        />
      )}
    />
  );
};
export default ControlledNumericTextField;
